import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import SEO from "components/seo"
import Layout from "components/layout"
import { Other } from "components/pages/others/other"

const PrivacyPolicy = ({ path, pageContext, location }) => {
	const data = useStaticQuery(GET_POLICY)
	const lang = pageContext.langKey
	const pageData = lang === "en"
		? data?.wordPress?.page
		: data?.wordPress?.page?.translation
		|| data?.wordPress?.page

	const { seo } = lang === "en"
		? data.wordPress.page
		: data.wordPress.page.translation
		|| data.wordPress.page

		const SEODefaultImg = data.wordPress.featuredImage?.node?.sourceUrl

	return (
		<Layout path={path} lang={lang}>
			<SEO
				title={seo?.title}
				desc={seo?.metaDesc}
				fbImg={SEODefaultImg}
				fbTitle={seo?.opengraphTitle}
				fbDesc={seo?.opengraphDescription}
				url={location.href}
				twitterTitle={seo?.twitterTitle}
				twitterDesc={seo?.twitterDescription}
				twitterImg={SEODefaultImg}
			/>
			<Other data={pageData} />
		</Layout>
	)
}

export default PrivacyPolicy;

const GET_POLICY = graphql`
query GET_POLICY {
	wordPress {
		page(id: "privacy-policy", idType: URI) {
			title
			seo {
				title
				metaDesc
				twitterTitle
				twitterDescription
				opengraphTitle
				opengraphDescription
			}
			featuredImage {
				node {
					sourceUrl
				}
			}
			blocks {
				originalContent
			}
			translation(language: ID) {
				title
				blocks {
					originalContent
				}
			}
		}
	}
}
`