import React from 'react'

export const Other = ({ data }) => {
	return (
		<section className="py-main">
			<div className="container mw-xl">
				<h1>{data.title}</h1>
				<p className="text-gray-600 font-weight-light py-4">{data.text}</p>
				{data.blocks?.map((item, i) => {
					return (
						<div
							key={i}
							className="pb-3"
							dangerouslySetInnerHTML={{ __html: item.originalContent }}
						/>
					)
				})}
			</div>
		</section>
	)
}
